export default `
query Models(
  $account_id: String, 
  $application_id: String, 
  $limit: Int, 
  $offset: Int, 
  $name: String, 
  $license_type: String,
  $tag_name: String, 
  $include: [String!],
  $origin: String,
) {
  meta {
    application_ids
    deployment_environments {
      deployment_environment_id
      account_id
      type
      production_status
      name
    }
  }
  models(
    application_id: $application_id,
    account_id: $account_id,
    name: $name,
    tag_name: $tag_name,
    limit: $limit,
    offset: $offset,
    license_type: $license_type,
    origin: $origin,
    include: $include
  ) {
    items {
      ... on ModelSchema {
        name
        model_id
        description
        meta
        updated_at
        license_link
        slug
        license_type
        application_id
        origin
        created_at
        created_by {
          realm
          uid
        }
        tags {
          name
          value
        }
        updated_by {
          realm
          uid
        }
      }
    }
    total {
      all
      query
    }
  }
}
`;
