import React from 'react';
import { Link, TableColumn } from '@backstage/core-components';
import { IncidentWithTeams } from '../../types';
import * as styles from './styles';
import { SeverityChip } from '../UI/SeverityChip';
import { StatusChip } from '../UI/StatusChip';
import Tooltip from '@mui/material/Tooltip';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ChatIcon from '@mui/icons-material/Chat';
import { TeamReference } from 'plugin-ui-components';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import { SiOpsgenie } from 'react-icons/si';

export const columns: TableColumn<IncidentWithTeams>[] = [
  {
    title: 'Severity',
    field: 'severity',
    cellStyle: styles.smallColumnStyle,
    headerStyle: styles.smallColumnStyle,
    render: data => (
      <SeverityChip
        severity={data?.tags.find(item => item.includes('SEV')) || ''}
      />
    ),
  },
  {
    title: 'Status',
    field: 'status',
    cellStyle: styles.smallColumnStyle,
    headerStyle: styles.smallColumnStyle,
    render: data => <StatusChip status={data.status} />,
  },
  {
    title: 'Description',
    field: 'message',
    render: data => <Link to={data.links.web}>{data.message}</Link>,
  },
  {
    title: 'Owner',
    render: data => (
      <TeamReference
        team={data.extraProperties['Owning Team radical agility id']}
      />
    ),
  },
  {
    title: 'Responders',
    render: data => (
      <Box display="flex" flexWrap="wrap" gap={0.5}>
        {data.responders.map(r => (
          <Chip
            key={r.id}
            label={r.name}
            variant="outlined"
            size="small"
            sx={{ width: 'fit-content' }}
          />
        ))}
      </Box>
    ),
  },
  {
    title: 'Resources',
    render: data => (
      <div>
        <Tooltip
          title={
            !!data.extraProperties['24/7 Incident Chat']
              ? '24/7 Incident Chat'
              : ' No Incident Chat available'
          }
        >
          <IconButton
            disabled={
              !data.extraProperties.hasOwnProperty('24/7 Incident Chat')
            }
            target="_blank"
            href={data.extraProperties['24/7 Incident Chat'] || '#'}
            size="small"
          >
            <AssignmentIcon color="primary" fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip
          title={
            !!data.extraProperties['Post Mortem']
              ? 'Post Mortem'
              : 'No Post Mortem available'
          }
        >
          <IconButton
            disabled={!data.extraProperties.hasOwnProperty('Post Mortem')}
            target="_blank"
            href={data.extraProperties['Post Mortem'] || '#'}
            size="small"
          >
            <ChatIcon color="primary" fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Link to the incident in OpsGenie">
          <IconButton
            size="small"
            href={data.links.web}
            target="_blank"
            disabled={!data.links.web}
            sx={theme => ({ '& svg': { fill: theme.palette.primary.main } })}
          >
            <SiOpsgenie />
          </IconButton>
        </Tooltip>
      </div>
    ),
  },
  {
    title: 'Created At',
    field: 'createdAt',
    cellStyle: styles.mediumColumnStyle,
    headerStyle: styles.mediumColumnStyle,
    render: date => {
      const time = new Date(date.createdAt).toISODateString();
      return <time dateTime={time}>{time}</time>;
    },
  },
  {
    title: 'Updated At',
    field: 'updatedAt',
    cellStyle: styles.mediumColumnStyle,
    headerStyle: styles.mediumColumnStyle,
    render: date => {
      const time = new Date(date.updatedAt).toISODateString();
      return <time dateTime={time}>{time}</time>;
    },
  },
];
