import { CatalogApi } from '@backstage/plugin-catalog-react';
import { PersonalizationApi } from 'plugin-core';
import { getLedTeams, getMemberTeams } from '../hooks/useTeamsUtils';
import type { IApplicationsScorecardsContext } from 'plugin-scorecards';
import {
  calculateScorecardCompletion,
  getApplicationScorecards,
} from 'plugin-scorecards';

interface Props {
  username: string;
  catalogApi: CatalogApi;
  personalizationApi: PersonalizationApi;
  /** Meant to exclude the watched teams in case the user isn't the current logged-in user */
  includeWatched?: boolean;
}

export async function getUserTeams({
  username,
  catalogApi,
  personalizationApi,
  includeWatched = true,
}: Props): Promise<Omit<IUserTeams, 'accountableTeams'>> {
  const promises: Promise<IEntityGroup[]>[] = [
    getMemberTeams({ catalogApi, username }),
    getLedTeams({ catalogApi, username }),
    personalizationApi.getUnfollowedTeams(),
  ];
  if (includeWatched) promises.push(personalizationApi.getWatchedTeams());

  return await Promise.all(promises).then(res => {
    return {
      memberTeams: res[0] || [],
      ledTeams: res[1] || [],
      unfollowedTeams: res[2] || [],
      watchedTeams: res[3] || [],
    };
  });
}

export const mapFilterKeys = (key: string) => {
  switch (key) {
    case 'cyberweekRelevant':
      return 'cyberweek';
    case 'support247':
      return 'onCallSupport';
    default:
      return key;
  }
};

export const addCompletionPercentage = async (
  entities: IEntityComponent[],
  catalogApi: CatalogApi,
  context: IApplicationsScorecardsContext,
) => {
  let index = 0;
  const chunkSize = 100; // Process 100 items at a time

  const processChunk = async () => {
    const end = Math.min(index + chunkSize, entities.length);

    for (let i = index; i < end; i++) {
      const item = entities[i];
      const { assessments } = await getApplicationScorecards(
        item,
        context,
        catalogApi,
      );

      if (assessments.length) {
        item.metadata.scorecardCompletion =
          calculateScorecardCompletion(assessments);
      }
    }

    index = end;

    if (index < entities.length) {
      if (requestIdleCallback) {
        requestIdleCallback(processChunk);
      } else {
        setTimeout(processChunk, 0);
      }
    }
  };

  await processChunk();
};
