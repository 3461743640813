import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Autocomplete from '@mui/material/Autocomplete';
import { Link, LinkButton } from '@backstage/core-components';
import {
  ChangedLineProps,
  ChangeTracker,
  ChangeTypes,
  useAllTeams,
  useImagePreview,
  useIsAdmin,
} from 'plugin-ui-components';
import {
  formatTeamName,
  getObjectDiff,
  getTeamNameFromFullName,
  slugify,
} from 'plugin-core';
import { stepsValues } from './EditToolingPage/stepsValues';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { parseEntityRef } from '@backstage/catalog-model';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import styled from '@mui/styles/styled';
import { validateFile } from '../utils';
import { CNCFCategoryGroupings } from '../constants';
import { ToolCNCFCategories, ToolOrganization } from '../enums';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

interface ToolingFormProps {
  mode: 'create' | 'edit';
  entity?: IEntityTooling;
  onSubmit: (formData: any) => void;
  isLoading: boolean;
  canEdit?: boolean;
  onDelete?: VoidFunction;
}

interface FormControlledState {
  team: IEntityGroup | null;
  journey: string[];
  step: string[] | undefined;
  status?: string;
  organization?: string;
  cncf_category?: string;
}

export interface ToolingFormInput {
  title: string;
  name: string;
  owner: string;
  journey: string[];
  status: string;
  interface: string;
  training: string;
  purpose: string;
  support: string;
  url: string;
  chat: string;
  step: string[] | string;
  journeyDev?: string;
  journeyData?: string;
}

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 48 * 4.5 + 8,
      width: 250,
    },
  },
};

function sortTeams(res: IEntityGroup[] | undefined) {
  return res?.sort((a, b) =>
    getTeamNameFromFullName(a.spec?.fullName).localeCompare(
      getTeamNameFromFullName(b.spec?.fullName),
    ),
  );
}

export function ToolingForm(props: ToolingFormProps) {
  const { mode, entity, onSubmit, isLoading, canEdit, onDelete } = props;
  const { value: teams = [], loading: teamsLoading } = useAllTeams(sortTeams);
  const disabled = mode === 'edit' && !canEdit;
  const isAdmin = useIsAdmin();
  const [dialogToggle, setDialogToggle] = React.useState(false);
  const [journeyAlert, setJourneyAlert] = React.useState(false);
  const [formData, setFormData] = React.useState<ToolingFormInput>();
  const [logoFile, setLogoFile] = React.useState<File | null>();
  const [controlledState, setControlledState] =
    React.useState<FormControlledState>({
      journey: entity?.spec.journey || [],
      step: entity?.spec.step,
      status: entity?.spec.status ?? '',
      team: null,
      organization: entity?.spec.organization,
      cncf_category: entity?.spec.cncf_category,
    });

  // Disable step selection if no journey is selected
  const stepDisabled = disabled || controlledState.journey.length === 0;

  const stepTooltipText = stepDisabled
    ? 'Please select a Journey first to see available steps'
    : '';
  // Show confirmation modal when edit form is submitted
  const toggleConfirmation = (formInput: ToolingFormInput) => {
    const newFormData: Record<string, any> = {
      ...formInput,
      step: (formInput.step as string).split(',').filter(Boolean),
    };
    if (entity) {
      Object.keys(entity.spec).forEach(key => {
        // Fix the null value issue
        if (newFormData[key] === '') {
          newFormData[key] = null;
        }
      });
    }
    // No file selected, keep the old logo
    if (newFormData.logo !== null && !(newFormData.logo as File)?.size) {
      newFormData.logo = entity?.spec.logo;
    }
    setFormData(newFormData as ToolingFormInput);
    setDialogToggle(true);
  };

  const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const formInput = Object.fromEntries(data.entries()) as any;

    formInput.owner = controlledState.team?.spec.team_id ?? '';
    // Convert journey checkboxes to array
    formInput.journey = [];
    if (formInput.hasOwnProperty('journeyDev')) {
      formInput.journey.push('Development');
      delete formInput.journeyDev;
    }
    if (formInput.hasOwnProperty('journeyData')) {
      formInput.journey.push('Data');
      delete formInput.journeyData;
    }
    if (formInput.journey.length === 0) {
      // abort the submit and display an erorr message
      return setJourneyAlert(true);
    }
    // Enabling the removal of the logo
    if (logoFile === null) {
      formInput.logo = null;
    }
    // Show confirmation dialog in edit mode otherwise submit form
    return mode === 'create'
      ? onSubmit({
          ...formInput,
          step: (formInput.step as string).split(',').filter(Boolean),
          owner: controlledState.team?.spec?.team_id ?? '',
          sap_id: controlledState.team?.spec?.team_id,
          name: slugify(formInput.title),
        })
      : toggleConfirmation(formInput);
  };

  const handleJourneyChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    value: string,
  ) => {
    if (e.target.checked) {
      setControlledState({
        ...controlledState,
        journey: [...controlledState.journey, value],
      });
    } else {
      setControlledState({
        ...controlledState,
        journey: controlledState.journey.filter(item => item !== value),
      });
    }
  };

  // Get the changes between the entity and the form data
  const mapEntityChanges = (): Array<ChangedLineProps> => {
    return getObjectDiff(entity?.spec, formData, [
      'id',
      'createdBy',
      'createdAt',
      'lastUpdatedBy',
      'updatedAt',
      'sapId',
    ]).map(
      ({ key, oldValue, newValue }): ChangedLineProps => ({
        label: key.charAt(0).toUpperCase() + key.slice(1),
        oldValue,
        newValue,
        changeType: ChangeTypes.changed,
      }),
    );
  };

  React.useEffect(() => {
    // Set default team value in edit mode
    const ownerRelation = entity?.relations?.find(r => r.type === 'ownedBy');
    if (ownerRelation && teams.length) {
      const parsedOwner = parseEntityRef(ownerRelation.targetRef);
      const defaultTeam = teams.find(t => t.spec.team_id === parsedOwner.name);
      setControlledState(prev => ({
        ...prev,
        team: defaultTeam ?? null,
      }));
    }
  }, [entity, teams]);

  const { value: previewImage } = useImagePreview(logoFile);

  return (
    <form onSubmit={submitForm}>
      <Typography variant="h3" pb={2} children="Metadata" />
      <Grid component="section" container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            name="title"
            helperText="The official name of this tool or service."
            label="Title"
            variant="outlined"
            defaultValue={entity && entity.spec.title}
            required
            size="small"
            disabled={disabled}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Autocomplete
            options={teams}
            value={controlledState.team}
            onChange={(__, value) => {
              setControlledState({
                ...controlledState,
                team: value as IEntityGroup,
              });
            }}
            getOptionLabel={option => formatTeamName(option)}
            isOptionEqualToValue={(option, value) =>
              option.spec?.team_id === value.spec?.team_id
            }
            renderOption={(elProps, option) => (
              <li {...elProps} key={option?.metadata?.name}>
                {formatTeamName(option as IEntityGroup)}
              </li>
            )}
            renderInput={params => (
              <TextField
                {...params}
                helperText="The owning team should be composed of the people directly responsible for operating and maintaining this tool or service, its metadata, its resources. Owners will be able to edit this entity after creation."
                label="Owning Team"
                name="owner"
                required
              />
            )}
            size="small"
            disabled={teamsLoading || disabled}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="outlined-multiline-static"
            label="Purpose"
            helperText={
              <>
                Describe the purpose of this tool or service so that your users
                and stakeholders can find it more easily. For tips on how to
                write a good description, please check the{' '}
                <Link to="https://sunrise.docs.zalando.net/features/tooling-catalog/#how-to-write-a-great-ie-useful-discoverable-description-of-your-tooling-or-service">
                  available documentation
                </Link>
                .
              </>
            }
            name="purpose"
            multiline
            minRows={5}
            defaultValue={entity && entity.spec.purpose}
            variant="outlined"
            size="small"
            required
            disabled={disabled}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Box sx={{ mb: 3, display: 'flex', alignItems: 'top', gap: 1.5 }}>
            <Button
              component="label"
              role={undefined}
              variant="text"
              tabIndex={-1}
              sx={{ cursor: !disabled ? 'pointer' : 'not-allowed' }}
            >
              <VisuallyHiddenInput
                type="file"
                name="logo"
                accept=".jpg,.jpeg,.png"
                disabled={disabled}
                onChange={ev => {
                  const file = ev.target.files?.item(0);
                  if (file) {
                    validateFile(file)
                      .then(() => setLogoFile(file))
                      .catch((err: Error) => {
                        ev.target.value = '';
                        // eslint-disable-next-line no-alert
                        alert(err.message);
                      });
                  }
                }}
              />
              {(entity?.spec.logo || previewImage) && logoFile !== null ? (
                <img
                  alt="Tool logo"
                  src={previewImage || entity?.spec.logo}
                  width="100"
                  height="100"
                />
              ) : (
                <Box
                  sx={theme => ({
                    width: 100,
                    height: 100,
                    backgroundColor:
                      theme.palette.mode === 'light'
                        ? theme.palette.grey[200]
                        : theme.palette.grey[800],
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  })}
                >
                  <AddPhotoAlternateIcon />
                </Box>
              )}
            </Button>
            <Box py={0.5}>
              <Typography>Logo requirements:</Typography>
              <Box component="ul" sx={{ m: 0, pl: 2 }}>
                <Typography component="li" variant="caption">
                  Image type: jpg, png
                </Typography>
                <Typography component="li" variant="caption">
                  Max size: 1MB
                </Typography>
                <Typography component="li" variant="caption">
                  Dimensions: 100x100 px
                </Typography>
              </Box>
              {!!entity?.spec.logo && logoFile !== null && (
                <Button
                  variant="text"
                  color="error"
                  size="small"
                  onClick={() => setLogoFile(null)}
                >
                  Remove logo
                </Button>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Divider component="hr" sx={{ my: 4 }} />

      <Typography variant="h3" pb={2} children="Lifecycle and Journey" />
      <Grid component="section" container spacing={2}>
        <Grid item xs={12} md={6}>
          <Autocomplete
            options={['Adopt', 'Trial', 'Assess', 'Hold', 'Retired']}
            value={controlledState.status}
            onChange={(__, value) => {
              setControlledState({
                ...controlledState,
                status: value as string,
              });
            }}
            getOptionLabel={option => option}
            isOptionEqualToValue={(option, value) => option === value}
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                label="Status"
                helperText={
                  <>
                    The status that indicates to which degree this tooling or
                    service is recommended for users and stakeholders. For
                    details, please check the{' '}
                    <Link to="https://sunrise.docs.zalando.net/features/tooling-catalog/#definitions-of-tooling-catalog-statuses">
                      Definitions of Tooling Catalog Statuses
                    </Link>
                    .
                  </>
                }
                name="status"
                required
              />
            )}
            size="small"
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} md={6} />
        <Grid item xs={12} md={6}>
          <Box
            component="fieldset"
            sx={{
              mt: -0.25,
              pb: 0,
              border: '1px solid var(--disabled-color)',
              borderRadius: 1,
            }}
          >
            <FormLabel
              required
              component="legend"
              sx={theme => ({ px: 1, fontSize: theme.typography.caption })}
            >
              Journey
            </FormLabel>
            <FormGroup sx={{ mt: -1 }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'start',
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      name="journeyDev"
                      defaultChecked={
                        entity && entity.spec.journey.includes('Development')
                      }
                      onChange={e => handleJourneyChange(e, 'Development')}
                      disabled={disabled}
                    />
                  }
                  label="Development"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="journeyData"
                      defaultChecked={
                        entity && entity.spec.journey.includes('Data')
                      }
                      onChange={e => handleJourneyChange(e, 'Data')}
                      disabled={disabled}
                    />
                  }
                  label="Data"
                />
              </Box>
            </FormGroup>
          </Box>
          <FormHelperText sx={{ pl: 2 }}>
            What{' '}
            <Link to="https://engineering.docs.zalando.net/developer-journey/00-developer-journey/#developer-journey">
              customer journeys
            </Link>{' '}
            flow through it at some point?
          </FormHelperText>
        </Grid>
        <Grid item xs={12} md={6}>
          <Tooltip title={stepTooltipText} placement="top-end" arrow>
            <FormControl
              disabled={stepDisabled}
              variant="outlined"
              size="small"
              margin="dense"
              required
            >
              <InputLabel id="tooling-step-label">Steps</InputLabel>
              <Select
                variant="outlined"
                labelId="tooling-step-label"
                name="step"
                label="Steps"
                multiple
                onChange={val =>
                  setControlledState({
                    ...controlledState,
                    step: val.target.value as Array<string>,
                  })
                }
                value={controlledState.step || []}
                renderValue={selected => (
                  <Box display="flex" flexWrap="wrap" gap={0.5}>
                    {(selected as Array<string>).map(value => (
                      <Chip key={value} label={value} size="small" />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {controlledState.journey.map(item => {
                  return stepsValues[`${item as 'Development' | 'Data'}`].map(
                    step => (
                      <MenuItem key={step} value={step}>
                        {step}
                      </MenuItem>
                    ),
                  );
                })}
              </Select>
              <FormHelperText>
                The steps in the respective Software Development or Data Science
                journeys that this tool supports.
              </FormHelperText>
            </FormControl>
          </Tooltip>
        </Grid>
        <Grid item xs={12} md={6}>
          <Autocomplete
            options={Object.values(ToolOrganization)}
            value={controlledState.organization}
            onChange={(__, value) => {
              setControlledState({
                ...controlledState,
                organization: value as string,
              });
            }}
            getOptionLabel={option => option}
            isOptionEqualToValue={(option, value) => option === value}
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                label="Organization"
                helperText="If this tool or service is part of an organization's offering, please select it."
                name="organization"
              />
            )}
            size="small"
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Autocomplete
            options={Object.values(ToolCNCFCategories)}
            groupBy={option =>
              CNCFCategoryGroupings.get(option as any) ?? 'Other'
            }
            value={controlledState.cncf_category}
            onChange={(__, value) => {
              setControlledState({
                ...controlledState,
                cncf_category: value as string,
              });
            }}
            getOptionLabel={option => option}
            isOptionEqualToValue={(option, value) => option === value}
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                label="CNCF Category"
                helperText={
                  <>
                    Categorization according to the Cloud Native Computing
                    Foundation. To understand the different categories, check{' '}
                    <Link to="https://landscape.cncf.io/guide">this guide</Link>
                    .
                  </>
                }
                name="cncf_category"
              />
            )}
            size="small"
            disabled={disabled}
          />
        </Grid>
      </Grid>

      <Divider component="hr" sx={{ my: 4 }} />

      <Typography variant="h3" pb={2} children="Links to related resources" />
      <Grid component="section" container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            label="Interface URL"
            defaultValue={entity && entity.spec.interface}
            helperText="Optional:  Link to the user-facing interface of the service, if available."
            variant="outlined"
            name="interface"
            size="small"
            disabled={disabled}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Support"
            helperText="Please provide a URL or Email for support. This should be a support channel maintained by the Owning team for users to reach out in case of questions or issues."
            defaultValue={entity && entity.spec.support}
            variant="outlined"
            name="support"
            size="small"
            disabled={disabled}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Documentation URL"
            helperText="Link to the tooling documentation."
            defaultValue={entity && entity.spec.url}
            variant="outlined"
            name="url"
            size="small"
            disabled={disabled}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Chat URL"
            helperText="Optional:  Link to a Google Chat Space where users can seek advice on the tooling or service (e.g. community chat)."
            defaultValue={entity && entity.spec.chat}
            variant="outlined"
            name="chat"
            size="small"
            disabled={disabled}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Training"
            helperText="Optional:  Link to training resources for this tool or service (e.g. bookable training session or video)."
            defaultValue={entity && entity.spec.training}
            variant="outlined"
            name="training"
            size="small"
            disabled={disabled}
            fullWidth
          />
        </Grid>
      </Grid>

      <Box display="flex" mt={4} gap={2}>
        {mode === 'create' && (
          <LinkButton to="/tooling" variant="outlined">
            Cancel
          </LinkButton>
        )}
        <div className="spacer" />
        {isAdmin && onDelete && (
          <Button
            type="button"
            color="error"
            disabled={isLoading || disabled}
            onClick={onDelete}
          >
            Delete
          </Button>
        )}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={isLoading || disabled}
        >
          Submit
        </Button>
      </Box>

      {/* Edit form confirmation dialog */}
      <Dialog onClose={() => setDialogToggle(false)} open={dialogToggle}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to submit your changes?
          </DialogContentText>
          {formData && <ChangeTracker changes={mapEntityChanges()} />}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogToggle(false)}>Cancel</Button>
          <div className="spacer" />
          <Button
            variant="outlined"
            color="primary"
            disabled={isLoading}
            onClick={() => {
              onSubmit({
                ...formData,
                owner: controlledState.team?.spec?.team_id ?? '',
                sap_id: controlledState.team?.spec?.team_id,
                name: formData && slugify(formData.title),
              });
              setDialogToggle(false);
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      {/* No selected journey alert */}
      <Dialog onClose={() => setJourneyAlert(false)} open={journeyAlert}>
        <DialogTitle>Attention</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please select at least one journey
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setJourneyAlert(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </form>
  );
}
